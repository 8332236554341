import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { Buffer } from "buffer";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CustomHeader } from "layout";

import { useWeb3React } from "@web3-react/core";
import { usePersonalSign } from "hooks/usePersonalSign";
import { _loginWithWallet, _patchDiscordCode, submitReferral } from "state/auth/actions";
import { AppDispatch, RootState } from "store/store";
import { _getLeaderBoardData, _getNotifications, _getScoreData, _getSeason, _getSettings, _getTiers } from "state/profile/actions";

import { setIsLogin } from "state/auth/reducer";
import { notifyError } from "uiComponents/Toast";
import { connectionSocket, socket } from "socket";
import { NewTaskListener, TaskDeleteListener, TaskEditListener, UserTaskUpdateListener } from "socket/socketChannel";
import {
  addNewTradersPortTask,
  updateTaskDeletion,
  updateTaskEdit,
  updateTraderPortTaskStatus
} from "state/quests/reducer";
import "styles/App.css";
import { setIsWeb3ModalOpen } from "state/profile/reducer";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

import { getName } from "connectors/getConnectorName";



const HeroSection = lazy(() => import("components/HeroSection"));
const Dashboard = lazy(() => import("components/Dashboard"));
const LeaderBoard = lazy(() => import("components/LeaderBoard"));
const ReferralSection = lazy(() => import("components/Referral"));
const FooterSection = lazy(() => import("components/FooterSection"));
const MentoQuest = lazy(() => import("components/MentoQuest"));

function App() {
  if (!window.Buffer) window.Buffer = Buffer;

  const { isLogin } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const { isWeb3ModalOpen } = useSelector((state: RootState) => state.profile);

  const { chainId, isActive, account, connector } = useWeb3React();
  const token = localStorage.getItem("token");
  const userid = localStorage.getItem("id");
  const [isNavigatedFromDiscord, setIsNavigatedFromDiscord] = useState(false);
  const [discordId, setDiscordId] = useState("")
  const { personalSign } = usePersonalSign();

  const [prevAcc, setPrevAcc] = useState<any>(null);

  useEffect(() => {
    if (account) {
      localStorage.setItem("connectorId", getName(connector))

    }
  }, [])
  const NOTIFICATION_ID = process.env.REACT_APP_NOTIFICATION_ID;

  useEffect(() => {
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: NOTIFICATION_ID || "4b625eec-e250-4327-8b00-1793f3fd9e1b"
    });

    if (userid) {
      beamsClient
        .start()
        .then(() => beamsClient.addDeviceInterest(userid))
        .then(() => console.log("Successfully registered and subscribed!"))
        .catch(console.error);
    }
  }, [userid]);

  useEffect(() => {
    const requestNotificationPermission = async () => {
      if ("Notification" in window) {
        try {
          const permission = await Notification.requestPermission();
          console.log("PERMISSION", permission);
          if (permission === "granted") {
            console.log("Notification permission granted.");
          } else {
            console.log("Notification permission denied.");
          }
        } catch (error) {
          console.error("Error requesting notification permission:", error);
        }
      } else {
        console.log("This browser does not support desktop notification");
      }
    };

    requestNotificationPermission();
  }, []);

  useEffect(() => {
    if (token && userid) {
      connectionSocket(userid);
    }
  }, [token, account, userid]);

  useEffect(() => {
    if (userid && token) {
      NewTaskListener(newTaskListenerCallback);
      UserTaskUpdateListener(UserTaskUpdateListenerCallback);
      TaskEditListener(TaskEditListenerCallback);
      TaskDeleteListener(TaskDeleteListenerCallback);
    }

    return () => {
      socket?.disconnect();
    };
  }, [account, userid, token]);

  //TEIR
  const newTaskListenerCallback = (data: any) => {
    dispatch(addNewTradersPortTask(data));
  };
  const UserTaskUpdateListenerCallback = (data: any) => {
    dispatch(updateTraderPortTaskStatus(data));
  };

  const TaskEditListenerCallback = (data: any) => {
    dispatch(updateTaskEdit(data));
  };

  const TaskDeleteListenerCallback = (data: any) => {
    dispatch(updateTaskDeletion(data));
  };

  const disconnect = useCallback(async () => {
    localStorage.removeItem("connectorId");
    localStorage.removeItem("token");
    localStorage.removeItem("id");

    if (connector.deactivate) {
      connector.deactivate();
    } else {
      connector.resetState();
    }
    // @ts-expect-error close can be returned by wallet
    if (connector?.close) {
      // @ts-expect-error close can be returned by wallet
      await connector.close();
    }
    dispatch(setIsLogin(false));
    socket?.disconnect();
  }, []);



  useEffect(() => {
    const signMessage = async () => {
      const _userId = localStorage.getItem("id");
      const _token = localStorage.getItem("token");
      if (prevAcc && account && prevAcc !== account) {
        localStorage.removeItem('id')
        localStorage.removeItem('token')


        try {
          const { success, data } = await personalSign("Example `personal_sign` message");

          if (success) {
            localStorage.setItem("connectorId", getName(connector))
            await dispatch(
              _loginWithWallet({
                account: account ? account : "",
                chainId: chainId ? chainId?.toString() : "1",
                signedMessage: data,
                discordId: discordId
              })
            ).unwrap();
            setPrevAcc(account);
          } else {
            if (!success && data === "User rejected the request.") {
              notifyError("User rejected the request.");
              disconnect();
            }
          }
        } catch (error) {
          console.error("Error signing message or calling API:", error);
        }


      } else {
        if (_userId && _token) {
          dispatch(setIsLogin(true));
          setPrevAcc(account);
        } else {
          try {
            const { success, data } = await personalSign("Example `personal_sign` message");
            console.log("KKKKK", success, data);

            if (success) {
              localStorage.setItem("connectorId", getName(connector))
              await dispatch(
                _loginWithWallet({
                  account: account ? account : "",
                  chainId: chainId ? chainId?.toString() : "1",
                  signedMessage: data,
                  discordId: discordId
                })
              ).unwrap();
              setPrevAcc(account);
            } else {
              if (!success && data === "User rejected the request.") {
                notifyError("User rejected the request.");
                disconnect();
              }
            }
          } catch (error) {
            console.error("Error signing message or calling API:", error);
          }
        }

      }



    };

    signMessage();
  }, [personalSign, isActive, account]);

  useEffect(() => {
    const saveCodeToLocalStorage = () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const state = params.get("state");

      if (code && state) {
        dispatch(_patchDiscordCode({ code, state }));
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
      }
    };

    saveCodeToLocalStorage();
  }, []);

  useEffect(() => {
    const _token = localStorage.getItem("token");

    if (isLogin && account && _token) {
      dispatch(_getScoreData());
      dispatch(_getNotifications());
    }
  }, [isLogin, account]);

  useEffect(() => {
    dispatch(_getSeason());
    dispatch(_getLeaderBoardData());
    dispatch(_getSettings())
    dispatch(_getTiers())
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const discordUser = urlParams.get("discordUser");
    if (discordUser) {
      setDiscordId(discordUser)
      setIsNavigatedFromDiscord(true);
      dispatch(setIsWeb3ModalOpen(true));
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get("referrer");

    if (referrer && isLogin) {
      dispatch(submitReferral(referrer))
        .unwrap()
        .then(() => {
          urlParams.delete("referrer");
          const newUrl = window.location.origin + window.location.pathname + "?" + urlParams.toString();
          window.history.replaceState(null, "", newUrl);
          console.log("Referral submitted successfully");
        })
        .catch((error) => {
          urlParams.delete("referrer");
          const newUrl = window.location.origin + window.location.pathname + "?" + urlParams.toString();
          window.history.replaceState(null, "", newUrl);
          console.error("Error submitting referral:", error);
        });
    }
  }, [dispatch, isLogin]);

  return (
    <Layout className="layout">
      <Suspense fallback={<div>Loading</div>}>

        <div className="background_globe">
          <CustomHeader />
          {isNavigatedFromDiscord && !account && isWeb3ModalOpen && <div className="mirror-overlay"></div>}{" "}

          <HeroSection />
          <Dashboard />
          <MentoQuest />
        </div>
        <LeaderBoard />
        <ReferralSection />
        <div className="hr_div">
          <hr className="hr_tag" />
        </div>
        <div className="footer_globe">
          <FooterSection />
        </div>
      </Suspense>
    </Layout>
  );
}

export default App;


