import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LeaderBoardData, ScoreData } from "types";

interface ProfileState {
  isColor: string;
  multiplier: number;

  leaderBoardData: LeaderBoardData[];
  leaderBoardDataLoading: boolean;
  leaderBoardDataError: null | string;

  currentSeason: number;

  scoreData:ScoreData | null;
  scoreDataLoading: boolean;
  scoreDataError:null|string;


  seasonsData:any | null;
  seasonsLoading: boolean;
  seasonsError:null|string;


  settingsData:any | null;
  settingsLoading: boolean;
  settingsError:null|string;
  
  tiersData:any|null;
  tiersLoading:boolean;
  tiersError:null|string;





  notifications:[];
  
  //ModalState
  isWeb3ModalOpen:boolean;
  isNavigatedFromDiscord:boolean;
}

const initialState: ProfileState = {
  isColor: "#47383B",
  multiplier: 1,

  leaderBoardData: [],
  leaderBoardDataLoading: false,
  leaderBoardDataError: null,

  currentSeason:1,

  scoreData:null,
  scoreDataLoading: false,
  scoreDataError:null,


  seasonsData: null,
  seasonsLoading: false,
  seasonsError:null,

  settingsData: null,
  settingsLoading: false,
  settingsError:null,

  tiersData:null,
  tiersLoading:false,
  tiersError:null,

  notifications:[],
  isWeb3ModalOpen:false,
  isNavigatedFromDiscord:false


};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {

    setIsWeb3ModalOpen(state, action: PayloadAction<boolean>) {
      state.isWeb3ModalOpen = action.payload;
    },
    setIsNavigatedFromDiscord(state, action: PayloadAction<boolean>) {
      state.isNavigatedFromDiscord = action.payload;
    },
    setIsMentoColor(state, action: PayloadAction<string>) {
      state.isColor = action.payload;
    },
    setMultiplier(state, action: PayloadAction<number>) {
      state.multiplier = action.payload;
    },

    leaderBoardDataLoading(state) {
      state.leaderBoardDataLoading = true;
      state.leaderBoardDataError = null;
    },
    leaderBoardDataSuccess(state, action: any) {
      state.leaderBoardDataLoading = false;
      state.leaderBoardData = action.payload?.leaderboard;
      state.currentSeason = action?.payload?.season;
    },
    leaderBoardDataFailure(state, action: PayloadAction<string>) {
      state.leaderBoardDataLoading = false;
      state.leaderBoardDataError = action.payload;
    },

    scoreDataLoading(state) {
      state.scoreDataLoading = true;
      state.scoreDataError = null;
    },
    scoreDataSuccess(state, action: any) {
      state.scoreDataLoading = false;
      state.scoreData = action.payload;
    },
    scoreDataFailure(state, action: PayloadAction<string>) {
      state.scoreDataLoading = false;
      state.scoreDataError = action.payload;
    },


    seasonsDataLoading(state) {
      state.seasonsLoading = true;
      state.seasonsError = null;
    },
    seasonsDataSuccess(state, action: any) {
      state.seasonsLoading = false;
      state.seasonsData = action.payload;
    },
    seasonsDataFailure(state, action: PayloadAction<string>) {
      state.seasonsLoading = false;
      state.seasonsError = action.payload;
    },


    settingsDataLoading(state) {
      state.settingsLoading = true;
      state.settingsError = null;
    },
    settingsDataSuccess(state, action: any) {
      state.settingsLoading = false;
      state.settingsData = action.payload;
    },
    settingsDataFailure(state, action: PayloadAction<string>) {
      state.settingsLoading = false;
      state.settingsError = action.payload;
    },


    tiersDataLoading(state) {
      state.tiersLoading = true;
      state.tiersError = null;
    },
    tiersDataSuccess(state, action: any) {
      state.tiersLoading = false;
      state.tiersData = action.payload;
    },
    tiersDataFailure(state, action: PayloadAction<string>) {
      state.tiersLoading = false;
      state.tiersError = action.payload;
    },

    setNotifications(state,action:PayloadAction<any>){
      state.notifications = action.payload

    }
  }
});

// Export the action creators and the reducer
export const { tiersDataLoading,tiersDataSuccess,tiersDataFailure,setIsNavigatedFromDiscord ,setIsWeb3ModalOpen, setIsMentoColor, setMultiplier, leaderBoardDataLoading, leaderBoardDataSuccess, leaderBoardDataFailure,scoreDataLoading,scoreDataSuccess,scoreDataFailure, setNotifications, seasonsDataLoading, seasonsDataSuccess, seasonsDataFailure,settingsDataLoading,settingsDataSuccess,settingsDataFailure } = profileSlice.actions;
export default profileSlice.reducer;
