import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";

export const n6 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 6
});
export const n4 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 4
});

export const c2 = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to string
 * @param {number} n number of chars to keep at front/end
 * @returns {string}
 */
export const getEllipsisTxt = (str: string, n = 6) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return "";
};

export const tokenValue = (value: number, decimals: number) => (decimals ? value / Math.pow(10, decimals) : value);

/**
 * Return a formatted string with the symbol at the end
 * @param {number} value integer value
 * @param {number} decimals number of decimals
 * @param {string} symbol token symbol
 * @returns {string}
 */
export const tokenValueTxt = (value: number, decimals: number, symbol: string) =>
  `${n4.format(tokenValue(value, decimals))} ${symbol}`;

export function parseBigNumberToFloat(val: BigNumber, decimals = 18) {
  if (!val) {
    return 0;
  }

  const formatted = formatUnits(val, decimals);
  const parsed = parseFloat(formatted);
  return parsed;
}

export const formatCamelCase = (text:any) => {
  let result = text && text?.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
  result = result.replace(/\b\w/g, (char:any) => char.toUpperCase())
  return result
}

export const shortenAddress = (address:string) => {
  if (!address) return "";
  return `${address.slice(0, 10)}...${address.slice(-6)}`;
};

export const formatNumber = (num: any) => {
  if (num === 0 || num === undefined || num === null) {
    return '0';
  }

  if (typeof num !== 'number') {
    return num; // return as is if it's not a number
  }

  const absNum = Math.abs(num);  // absolute value of the number
  let suffix = '';
  let formattedNum = num;

  if (absNum >= 1_000_000) {
    formattedNum = num / 1_000_000;
    suffix = 'M';
  } else if (absNum >= 1_000) {
    formattedNum = num / 1_000;
    suffix = 'K';
  }

  // Use Intl.NumberFormat to format the number with commas
  return new Intl.NumberFormat().format(formattedNum) + suffix;
};


export function getFirstFiveLetters(inputString:string) {
  // Check if the string length is more than 5 characters
  if (inputString.length > 5) {
    return inputString.substring(0, 5) + '...';
  } else {
    return inputString;
  }
}
