import { Web3ReactProvider } from "@web3-react/core";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import connectors from "./connectors";
import { store } from "store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = createRoot(document.getElementById("root") as HTMLElement);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("Service Worker registered with scope:", registration.scope);
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}

root.render(
  <Provider store={store}>
    <Web3ReactProvider connectors={connectors}>
      <ToastContainer />
      <App />
    </Web3ReactProvider>
  </Provider>
);
