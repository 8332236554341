import { useEffect, useState } from "react";

import { theme } from "styles/theme";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const changeWindowSize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", changeWindowSize);
    return () => window.removeEventListener("resize", changeWindowSize);
  }, []);

  //Temporary addition will fix it later o making it more dynamic
  const isMobile = windowSize.width <= theme.breakpoints.small;
  const isTablet = windowSize.width <= theme.breakpoints.medium;
  const isSmallScreen = windowSize.width <= theme.breakpoints.large;
  const isAir4 = windowSize.width <= theme.breakpoints.air4;
  const isAmbigous = windowSize.width === 540;
  const isGalaxyTab = windowSize.width >= 853 && windowSize.width <= 884;

  return { ...windowSize, isMobile, isTablet, isSmallScreen, isAmbigous,isAir4,isGalaxyTab };
};
