import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//TIER
interface TradersPortTaskState {
  tradersPortTask: any[];
  tradersPortTaskLoading: boolean;
  tradersPortTaskError: boolean | null | string;

  socialsPortTask: [];
  socialsPortTaskLoading: boolean;
  socialsPortTaskError: boolean | null | string;
}

const initialState: TradersPortTaskState = {
  tradersPortTask: [],
  tradersPortTaskLoading: false,
  tradersPortTaskError: null,

  socialsPortTask: [],
  socialsPortTaskLoading: false,
  socialsPortTaskError: null
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    traderPortTaskLoading(state) {
      state.tradersPortTaskLoading = true;
      state.tradersPortTaskError = null;
    },
    traderPortTaskSuccess(state, action: any) {
      console.log("AAAAA", action);
      state.tradersPortTaskLoading = false;
      state.tradersPortTask = action.payload;
    },
    traderPortTaskFailure(state, action: PayloadAction<string>) {
      state.tradersPortTaskLoading = false;
      state.tradersPortTaskError = action.payload;
    },

    addNewTradersPortTask(state, action: any) {
      console.log("FINEWORKING", action)
      state.tradersPortTask.push(action.payload);
    },

    updateTraderPortTaskStatus(state, action: any) {
      const { taskId:id, status } = action.payload;
      const task = state.tradersPortTask.find((task) => task?.taskId?._id === id);
      if (task) {
        task.progress = status;
      }
    },

    updateTaskEdit(state, action) {
      console.log("ACTIONEDIT", action)
      const  newTask  = action.payload; 
      state.tradersPortTask = state.tradersPortTask.map((task) => 
        task.taskId?._id === newTask?.taskId?._id ? newTask : task 
      );
    },    

    updateTaskDeletion(state, action) {
      console.log("AAACC",action)
      const  id  = action.payload;
      state.tradersPortTask = state.tradersPortTask.filter((task) => task.taskId?._id !== id);
    },
    

    socialsPortTaskLoading(state) {
      state.socialsPortTaskLoading = true;
      state.socialsPortTaskError = null;
    },
    socialsPortTaskSuccess(state, action: any) {
      state.socialsPortTaskLoading = false;
      state.socialsPortTask = action.payload;
    },
    socialsPortTaskFailure(state, action: PayloadAction<string>) {
      state.socialsPortTaskLoading = false;
      state.socialsPortTaskError = action.payload;
    }
  }
});

export const {
  traderPortTaskLoading,
  traderPortTaskSuccess,
  traderPortTaskFailure,
  socialsPortTaskLoading,
  socialsPortTaskSuccess,
  socialsPortTaskFailure,
  addNewTradersPortTask,
  updateTraderPortTaskStatus,
  updateTaskEdit,
  updateTaskDeletion,
} = taskSlice.actions;

export default taskSlice.reducer;
