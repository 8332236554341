import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../state/auth/reducer";
import profileReducer from "../state/profile/reducer";
import tasksReudcer from "../state/quests/reducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    tasks: tasksReudcer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat()
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
