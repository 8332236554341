import { SOCKET_BASE_URL } from "interceptor/axiosInstance";
import { io, Socket } from "socket.io-client";

let socket: Socket | null = null;

const connectionSocket = async (userId: any) => {
  const token = localStorage.getItem("token");

  socket = io(`${SOCKET_BASE_URL}`, {
    query: {
      userId,
      token: token,
      isAdmin: "false"
    },
    transports: ["websocket", "polling"]
  });

  socket.on("connect", () => {
    console.log("ConnectedSocket", socket?.connected, socket);
  });

  socket.on("connect_error", (error) => {
    console.error("Connectionerror:", error?.message, socket);
  });

  socket.on("error", (error) => {
    console.error("Error:", error);
  });

  socket.on("disconnect", () => {
    console.log("Disconnect");
  });
};

export { connectionSocket, socket };
