import axios from "axios";
import { metaMask } from "connectors/metaMask";
import { walletConnect } from "connectors/walletConnect";
import { socket } from "socket";
// import { notifyError } from "uiComponents/Toast";
// import { setIsLogin } from "state/auth/reducer";
// import { useDispatch } from "react-redux";

export const BASE_URL = "https://dev.mento.org/api/";
export const SOCKET_BASE_URL = "https://dev-backend.mento.org/";

const axiosInstance = axios.create({
  baseURL: BASE_URL
});

// Disconnect function
const disconnect = async () => {
  console.log("Disconnected from the server.");
  const connector = metaMask || walletConnect;

  if (connector.deactivate) {
    connector.deactivate();
  } else {
    connector.resetState();
  }

  // @ts-expect-error close can be returned by wallet
  if (connector?.close) {
    // @ts-expect-error close can be returned by wallet
    await connector.close();
  }
  socket?.disconnect();
  // dispatch(setIsLogin(false));
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("UNAUTHORIZED", error);
    if ((error.response && error.response.statusCode === 401) || error.response.status === 401) {
      // const dispatch = useDispatch();
      // await disconnect(dispatch);
      console.log("Unauthorized! Redirecting to login...");
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("connectorId");
      // notifyError("Token Expired: Please Connect Again");
      disconnect();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
