import { lazy, useState } from "react";
import { Layout } from "antd";
import { ReactComponent as Mentos } from "assets/svg/Logo.svg";
import "./CustomHeader.css";
import {ReactComponent as Menu} from "assets/svg/Menu.svg"

const { Header } = Layout;

const ConnectDiscord = lazy(() => import("../../components/Account/ConnectDiscord"));
const MenuItems = lazy(() => import("../../components/Account/MenuItems"));
const ConnectAccount = lazy(() => import("../../components/Account/ConnectAccount"));

const CustomHeader = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  return (
    <Header className="header_custom">
      <div className="header__left">
        <Mentos className="mentos_icon" />
      </div>

      <div className={`header__right ${isMobileMenuOpen ? "mobile-open" : ""}`}>
        <MenuItems />
        <div className="header_button_wrapper">
          <ConnectDiscord />
          <ConnectAccount />
        </div>
      </div>

      <div className="hamburger-icon" onClick={toggleMobileMenu}>
        <Menu style={{ color: '#A5E5F7' }} />
      </div>
    </Header>
  );
};

export default CustomHeader;
