import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "interceptor/axiosInstance";
// import { notifyError } from "uiComponents/Toast";
import {
  leaderBoardDataFailure,
  leaderBoardDataLoading,
  leaderBoardDataSuccess,
  scoreDataFailure,
  scoreDataLoading,
  scoreDataSuccess,
  seasonsDataFailure,
  seasonsDataLoading,
  seasonsDataSuccess,
  setNotifications,
  settingsDataFailure,
  settingsDataLoading,
  settingsDataSuccess,
  tiersDataFailure,
  tiersDataLoading,
  tiersDataSuccess
} from "./reducer";

export const _getLeaderBoardData = createAsyncThunk("profile/leaderboard", async (_, { dispatch }) => {
  dispatch(leaderBoardDataLoading());

  try {
    const response = await axiosInstance.get(`users/leaderboard`);
    console.log("WORTHY", response);
    dispatch(leaderBoardDataSuccess(response?.data?.data));
  } catch (error: any) {
    dispatch(leaderBoardDataFailure(error.message || "Error submitting referral"));
    throw error;
  }
});

export const _getScoreData = createAsyncThunk("profile/score", async (_, { dispatch }) => {
  dispatch(scoreDataLoading());

  try {
    const response = await axiosInstance.get(`users/score`);
    console.log("JJPJHH", response?.data?.data);
    dispatch(scoreDataSuccess(response?.data?.data));
  } catch (error: any) {
    // notifyError("Error");
    dispatch(scoreDataFailure(error.message || "Error submitting referral"));
    throw error;
  }
});

export const _getSeason = createAsyncThunk("profile/seasons", async (_, { dispatch }) => {
  dispatch(seasonsDataLoading());

  try {
    const response = await axiosInstance.get(`seasons`);
    dispatch(seasonsDataSuccess(response?.data?.data));
  } catch (error: any) {
    dispatch(seasonsDataFailure(error.message || "Error submitting referral"));
    throw error;
  }
});

export const _getNotifications = createAsyncThunk("profile/notifications", async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`notifications`);
    dispatch(setNotifications(response?.data?.data));
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data || "An error occurred");
  }
});


export const _getSettings = createAsyncThunk("profile/settings", async (_, { dispatch }) => {
  dispatch(settingsDataLoading());

  try {
    const response = await axiosInstance.get(`settings`);
    console.log("RESPONMSE", response?.data?.data)
    dispatch(settingsDataSuccess(response?.data?.data));
  } catch (error: any) {
    dispatch(settingsDataFailure(error.message || "Error submitting referral"));
    throw error;
  }
});


export const _getTiers = createAsyncThunk("profile/tiers", async (_, { dispatch }) => {
  dispatch(tiersDataLoading());

  try {
    const response = await axiosInstance.get(`tiers/user`);
    console.log("RESPONMSE", response?.data?.data)
    dispatch(tiersDataSuccess(response?.data?.data));
  } catch (error: any) {
    dispatch(tiersDataFailure(error.message || "Error submitting referral"));
    throw error;
  }
});
