import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'interceptor/axiosInstance';
import { connectDiscordSuccess, connectDiscordFailure, setIsLogin, submitReferralStart, submitReferralSuccess, submitReferralFailure, setUserId, isDiscordConnectedSuccess, isDiscordConnectedFailure } from './reducer';
import { notifyError } from 'uiComponents/Toast';
import { _getScoreData } from 'state/profile/actions';
import { DiscordError } from 'types';
import axios from 'axios';


export const _loginWithWallet = createAsyncThunk(
  'auth/loginWithWallet',
  async ({ account, chainId, signedMessage,discordId }: { account: string; chainId: string; signedMessage: string; discordId:string  }, { dispatch }) => {
    try {
      const response = await axiosInstance.post("auth/login/wallet", {
        walletAddress: account,
        chainId: chainId?.toString(),
        signedMessage,
        discordId
      });
      
      console.log("KKK", response);

      dispatch(setIsLogin(true));
      localStorage.setItem("token", response.data.data.access_token);
      localStorage.setItem("id", response.data.data.id);
      dispatch(setUserId(response.data.data.id));

      return response.data; 
    } catch (error) {
      console.error("Error during wallet login:", error);

     

    }
  }
);

  
export const connectDiscord = createAsyncThunk(
  'auth/connectDiscord',
  async (_, { dispatch }) => {
    try {
      const response = await axiosInstance.get("discord/auth");
      const discordLink = response.data?.data?.url;
      if (discordLink) {
        window.location.href = discordLink; // Navigate to the link in the same page
        dispatch(connectDiscordSuccess(discordLink));
      }
      console.log("Discord connection successful:", response.data);
    } catch (error) {
      notifyError('Error');
      dispatch(connectDiscordFailure("Failed to connect to Discord")); 
      throw error; 
    }
  }
);



export const _patchDiscordCode = createAsyncThunk(
  'auth/connectDiscord',
  async ({ code, state }: { code: string; state: string }, { dispatch }) => {
    try {
      const response = await axiosInstance.patch("discord/auth", {
        state: state,
        code: code,
      });
      const data = response.data?.data;
      console.log("DJJDJ", data);
      
      if (data) {
        dispatch(isDiscordConnectedSuccess(data));
        localStorage.setItem('accessToken', data?.accessToken)
      }
      dispatch(_getScoreData());
      console.log("Discord code successfully dispatched", response.data);
    } catch (error) {
      let errorMessage = "Failed to dispatch";

      // Check if the error is an Axios error
      if (axios.isAxiosError(error) && error.response) {
        const discordError: DiscordError = error.response.data;
        errorMessage = discordError.message || "Failed to dispatch"; // Fallback message
        console.log("ERRORPATHC", errorMessage);
        notifyError(errorMessage)

      } else {
        console.log("Unexpected error:", error);
      }

      dispatch(isDiscordConnectedFailure(errorMessage));
      throw error; 
    }
  }
);


export const submitReferral = createAsyncThunk(
    'auth/submitReferral',
    async (referralLink: string, { dispatch }) => {
      dispatch(submitReferralStart());
      try { 
       const response =  await axiosInstance.post("users/referral", { referrer: referralLink });
       console.log("JJJ", response)
        dispatch(submitReferralSuccess());
        dispatch(_getScoreData());
      } catch (error: any) {
        console.log("EEE", error)
        notifyError(error?.response?.data?.message)
        dispatch(submitReferralFailure(error.message || "Error submitting referral"));
        throw error; 
      }
    }
  );
  