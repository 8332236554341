import { socket } from "./index";

export const NewTaskListener = (CB: any) => {
  socket?.on("onTaskCreationForUser", (data: any) => {
    CB && CB(data);
  });
};

export const UserTaskUpdateListener = (CB: any) => {
  socket?.on("onUserTaskProgressUpdate", (data: any) => {
    CB && CB(data);
  });
};

export const TaskEditListener = (CB: any) => {
  socket?.on("onTaskUpdateForUser", (data: any) => {
    CB && CB(data);
  });
};

export const TaskDeleteListener = (CB: any) => {
  socket?.on("onTaskDelete", (data: any) => {
    CB && CB(data);
  });
};
